import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  Paper,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { TENANT_URL } from '../../../config';

const CustomPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '10px',
  boxShadow: '0px 14px 42px 0px #080F340F',
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
  },
  '& .Mui-selected': {
    backgroundColor: '#F0645B !important',
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
}));

const PaymentHistory = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);

  const fetchDataSubscription = async (url) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchDataSubscription('/customer/app/transaction/history');
  }, []);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box
      sx={{
        padding: '20px 2.5em',
        maxWidth: '100%',
        margin: '0 auto',
        height: '120vh',
      }}
    >
      <CustomPaper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight="500" fontSize={13}>
                    Sr.#
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="500" fontSize={13}>
                    Payment Method
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="500" fontSize={13}>
                    Payment Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="500" fontSize={13}>
                    Payment Amount
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentRows.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{indexOfFirstRow + index + 1}</TableCell>
                  <TableCell>{row.payment_type}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomPaper>

      <Box display="flex" justifyContent="end" marginTop="16px" padding="16px">
        <CustomPagination
          count={Math.ceil(data.length / rowsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default PaymentHistory;
