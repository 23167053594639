import { Box, Button } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { useProfile } from '../../customHooks/useProfile';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { TENANT_URL } from '../../../config';

const PickupGroupActionColumn = ({ row }) => {

  // console.log("row:",row);
  const { setCurrentCustomer } = useProfile();
  const navigate = useNavigate();
  const [isDisplayed, setIsDisplayed] = useState(false);
  const menuRef = useRef(null);

  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_delivery_groups_tab');
  const actions = [
    {
      name: 'View Customer',
      permission: permissions.view,
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsDisplayed(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);


  const handleStatusUpdate = async (status) => {
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      const config = {
        method: 'put', // or 'post', based on your API
        url: `${TENANT_URL}/daily-delivery/${row.id}/status`, // Replace with your API endpoint
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          status:status, // Update the status
        },
      };
      const response = await axios(config);
      console.log('Response:', response);
      if (response.status === 200) {
        // alert('Status updated successfully!');
        setIsDisplayed(false); // Close the menu after a successful update
        // Optionally, trigger a reload or update state
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status.');
    }
  };



  return (
    <div>
      <span>
        {row.order_status == "Pending" &&(
          <>
              <Button
                  variant="filled"
                  onClick={() => {
                    setIsDisplayed(!isDisplayed);
                  }}
                  sx={{
                    borderRadius: '20px',
                    textTransform: 'capitalize',
                    px: { md: 2, lg: 2, sm: 2 },
                    borderRadius: '50px',
                    fontWeight: 400,
                    color: 'white',
                    boxShadow: 'none',
                    minWidth: '50px',
                  }}
                >
                  <span style={{ paddingLeft: '11px', paddingRight: '11px' }}>
                    Actions
                  </span>
              </Button>
              {isDisplayed && (
                <Box
                  ref={menuRef}
                  style={{
                    zIndex: 200,
                    alignContent: 'center',
                    backgroundColor: 'white',
                    position: 'absolute',
                    right: '24px',
                    borderRadius: '4px',
                    width: '135px',
                    display: 'block',
                    boxShadow: '0px 2px 2px 0px #00000026',
                    marginTop: '5px',
                    '& :hover': {
                      borderRadius: '4px',
                    },
                  }}
                >
                  {actions.map(
                    (action) =>
                      action.permission && (
                        // <Box key={action.name}>
                        //   <Button
                        //     sx={{
                        //       width: '100%',
                        //       my: 0.1,
                        //       fontSize: '12px',
                        //       borderRadius: '4px',
                        //     }}
                        //     onClick={handleMarkAsPickedUp}
                        //   >
                        //     Mark as picked up
                        //   </Button>
                        // </Box>
                        
                        <>
                          <Box key="mark-picked">
                            <Button
                              sx={{
                                width: "100%",
                                my: 0.1,
                                fontSize: "12px",
                                borderRadius: "4px",
                              }}
                              onClick={() => handleStatusUpdate("completed")} // ✅ Updates as "completed"
                            >
                              Mark as Picked Up
                            </Button>
                          </Box><Box key="cancel-order">
                            <Button
                              sx={{
                                width: "100%",
                                my: 0.1,
                                fontSize: "12px",
                                borderRadius: "4px",
                                backgroundColor: "red",
                                color: "white",
                                "&:hover": {
                                  backgroundColor: "darkred", // Darker shade on hover
                                  color: "white", // Ensure text remains visible
                                },
                              }}
                              
                              onClick={() => handleStatusUpdate("cancelled")} // ✅ Updates as "canceled"
                            >
                              Mark as cancelled
                            </Button>
                          </Box></>

                      )
                  )}
                </Box>
              )}
          </>
        )}
        
      </span>
    </div>
  );
};

export default PickupGroupActionColumn;
