import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  AlertstatsStyles,
  avatarStyles,
  barchartStyles,
  BigscrollableListStyles,
  imageContainerStyles,
  listItemNotificationsStyles,
  listItemTextStyles,
  notificationsStyles,
  rootStyles,
  scrollableListStyles,
  scrollableListStylesButtons,
  statsStyles,
} from './styles/styles';

import MonthlyRevenue from './components/MonthlyRevenue';
import RoundedIconItem from './components/RoundedIconItem';
import AlertRoundedIconItem from './components/AlertRoundedIconItem';
import RoundedItemSmall from './components/RoundedItemSmall';
import MealImage from '../../assets/dashboard/meal.svg';
import DeliveryImage from '../../assets/dashboard/delivery.svg';
import PaymentDueImage from '../../assets/dashboard/payment_due.svg';
import PaymentRecieveImage from '../../assets/dashboard/payment_recieve.svg';
import PaymentFailedImage from '../../assets/dashboard/payment_failed.svg';
import ShoppingCartImage from '../../assets/dashboard/shopping_cart.svg';
import ArrowBoxImage from '../../assets/dashboard/arrow_box.svg';
import DangerImage from '../../assets/dashboard/danger.svg';
import SaleTagImage from '../../assets/dashboard/sale_tag.svg';
import ClipboardImage from '../../assets/dashboard/clipboard.svg';
import MessageImage from '../../assets/dashboard/messages.svg';
import Spinner from '../../../components/utils/spinner';
import avatar from '../../../images/avatar.jpeg';
import { useDashboard } from '../../customHooks/useDashboard';
import { Chart, registerables } from 'chart.js';
import ArrowIcon from './components/ArrowIcon';
import { CameraAlt, NoPhotography } from '@mui/icons-material';
import DeliveryImageModal from './components/modals/DeliveryImageModal/DeliveryImageModal';

Chart.register(...registerables);

const paymentsData = [
  {
    payment: '30',
    name: 'John Deo',
  },
  {
    payment: '60',
    name: 'Toby',
  },
  {
    payment: '20',
    name: 'Roman',
  },
  {
    payment: '80',
    name: 'Smith',
  },
  {
    payment: '50',
    name: 'William',
  },
];

const DelivelyData = [
  {
    to: 'John Deo',
    from: 'Toby',
  },
  {
    to: 'Roman',
    from: 'Smith',
  },
  {
    to: 'James',
    from: 'John Deo',
  },
  {
    to: 'William',
    from: 'Jack',
  },
  {
    to: 'David',
    from: 'Roman',
  },
  {
    to: 'James',
    from: 'John Deo',
  },
  {
    to: 'William',
    from: 'Jack',
  },
  {
    to: 'Thomes',
    from: 'William',
  },
  {
    to: 'David',
    from: 'Thomes',
  },
];

const TenantDasboard = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const {
    dashboardCardData,
    setDashboardCardData,
    getDashboardCards,
    paymentCard,
    paymentNotification,
    graphValue,
    deliveryNotification,
    secondSelected,
    setSecondSelected,
    smsNotifications,
  } = useDashboard();

  const [cardAPiData, setCardApiData] = useState({
    todays_total_deliveries: 10,
    all_customers: 100,
    planed_deliveries_tomorrow: 0,
    todays_halted_deliveries: 120,
    total_deliveries: 1000,
    active_subscriber: 1200,
    non_active_subscriber: 2000,
    todays_pending_deliveries: 900,
    todays_completed_deliveries: 900,
    active_meals: 2000,
    active_food_items: 1000,
  });
  const CardData = [
    {
      id: 1,
      img: ShoppingCartImage,
      amount: dashboardCardData.todays_total_deliveries,
      text: 'Total Delivery Today',
      success: true,
      percentage: dashboardCardData.percentage_today_delivery,
    },
    {
      id: 2,
      img: ArrowBoxImage,
      amount: dashboardCardData.all_customers,
      text: 'Total Customers',
      success: true,
      percentage: dashboardCardData.$total_customer_percentage,
    },
    {
      id: 3,
      img: ArrowBoxImage,
      amount: dashboardCardData.total_campaigns,
      text: 'Total Campigns',
      success: true,
      percentage: dashboardCardData.$percentage_total_campaigns,
    },
    {
      id: 4,
      img: DangerImage,
      amount: dashboardCardData.todays_halted_deliveries,
      text: 'Deliveries Halted Today',
      success: true,
      percentage: dashboardCardData.halted_percentage,
      border: false,
    },
    {
      id: 5,
      img: SaleTagImage,
      amount: dashboardCardData.total_meal_delivered,
      text: 'Total Meal Delivered',
      success: true,
      percentage: dashboardCardData.total_delivery_percentage,
    },
    {
      id: 6,
      img: ClipboardImage,
      amount: dashboardCardData.active_subscriber,
      text: 'Active Subscriber',
      success: true,
      percentage: dashboardCardData.total_active_subscriber_percentage,
    },
    {
      id: 7,
      img: ClipboardImage,
      amount: dashboardCardData.non_active_subscriber,
      text: 'Non Active Subscriber',
      success: true,
      percentage: dashboardCardData.total_non_active_subscriber_percentage,
    },
    {
      id: 8,
      img: MessageImage,
      amount: dashboardCardData.total_sms_sent,
      text: 'Total SMS sent',
      success: true,
      percentage: dashboardCardData.total_sms_percentage,
    },
    {
      id: 9,
      img: ArrowBoxImage,
      amount: dashboardCardData.total_delivered_orders,
      text: 'Total Delivered',
      success: true,
      percentage: dashboardCardData.$percentage_delivered_orders,
    },
    {
      id: 10,
      img: ArrowBoxImage,
      amount: dashboardCardData.total_cancelled_orders,
      text: 'Canceled Deliveries',
      success: true,
      percentage: dashboardCardData.$percentage_cancelled_orders,
    },
  ];

  const handleImageClick = (img) => {
    setCurrentImage(img);
    setIsOpen(true);
  };

  useEffect(() => {
    const {
      planed_deliveries_tomorrow,
      todays_total_deliveries,
      all_customers,
      todays_halted_deliveries,
      active_subscriber,
      non_active_subscriber,
      total_deliveries,
      todays_pending_deliveries,
      todays_completed_deliveries,
      active_meals,
      active_food_items,
      active_menu_plans,
      active_addons,
      active_route_count,
    } = dashboardCardData;
    setCardApiData({
      planed_deliveries_tomorrow,
      todays_total_deliveries,
      all_customers,
      todays_halted_deliveries,
      active_subscriber,
      non_active_subscriber,
      total_deliveries,
      todays_pending_deliveries,
      todays_completed_deliveries,
      active_meals,
      active_food_items,
      active_menu_plans,
      active_addons,
      active_route_count,
    });
  }, [dashboardCardData]);
  const isZoomed = zoomLevel > 1.2;
  console.log('secondSelected', secondSelected);
  return (
    <>
      <DeliveryImageModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        img={currentImage}
      />
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <Box>
          <Grid container spacing={'24px'}>
            <Grid item xs={12}>
              <Grid container spacing={'24px'}>
                {CardData.map((item, i) => {
                  return (
                    <Grid key={i} item xs={12} sm={6} md={4} lg={2.4}>
                      <Box
                        sx={{
                          borderRadius: '15px',
                          border: '1px solid #F4F1F8',
                          background:
                            'var(--Glass-Fill, linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%))',
                          boxShadow: '0px 4px 4px 0px #00000026 !important',
                          backdropFilter: 'blur(20px)',
                          padding: '10px 12px 10px 20px',
                          minHeight: 104,
                          display: 'flex',
                          position: 'relative',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                            width: '100%',
                          }}
                        >
                          <img
                            src={item.img}
                            alt="ShoppingCartImage"
                            width={'68px'}
                          />

                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              gap: '8px',
                            }}
                          >
                            <Box
                              sx={{
                                fontSize: '28px',
                                fontWeight: '600',
                                lineHeight: '35.28px',
                                textAlign: 'left',
                              }}
                            >
                              {item.amount}
                            </Box>
                            <Box
                              sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '17.64px',
                                textAlign: 'left',
                              }}
                            >
                              {item.text}
                            </Box>
                            {/* <Box
                              sx={{
                                display: 'flex',
                                height: 'max-content',
                                position: 'absolute',
                                top: '12px',
                                right: '12px',
                                alignItems: 'center',
                                gap: '3px',
                              }}
                            >
                              <ArrowIcon
                                style={{
                                  transform:
                                    item.percentage >= 0
                                      ? 'rotate(0)'
                                      : 'rotate(180deg)',
                                }}
                                fill={
                                  item.percentage >= 0 ? '#54BA4A' : '#FF3364'
                                }
                              />
                              <Box
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color:
                                    item.percentage >= 0
                                      ? '#54BA4A'
                                      : '#FF3364',
                                  lineHeight: '17.64px',
                                }}
                              >
                                {item.percentage > 0
                                  ? `+${Number(item.percentage).toFixed(0)}%`
                                  : `${Number(item.percentage).toFixed(0)}%`}
                              </Box> */}
                            {/* </Box> */}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container columnSpacing={'24px'}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Grid container spacing={'24px'}>
                    <Grid item xs={12}>
                      <Paper sx={barchartStyles}>
                        <MonthlyRevenue
                          graphValue={graphValue}
                          secondSelected={secondSelected}
                          setSecondSelected={setSecondSelected}
                        />
                      </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <Paper
                        sx={{
                          ...notificationsStyles,
                          height: '314px',
                          display: 'flex',
                          flexDirection: 'column',
                          border: '1px solid rgb(243, 236, 243)',
                          boxShadow: '0px 4px 4px 0px #00000026',
                          gap: '19px',
                        }}
                        elevation={2}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: '20px',
                            fontWeight: '500',
                            fontFamily: 'Outfit',
                            lineHeight: '25.2px',
                            letterSpacing: '0.5px',
                            color: 'rgba(63, 47, 91, 1)',
                          }}
                          align="left"
                        >
                          Today's Payments & Notifications
                        </Typography>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={4} sm={4}>
                            <Paper elevation={2} sx={statsStyles}>
                              <Box sx={imageContainerStyles}>
                                <img
                                  src={PaymentRecieveImage}
                                  alt="earning_dot"
                                  style={{
                                    margin: '0',
                                    width: '50px',
                                    height: '50px',
                                  }}
                                />
                              </Box>
                              <Box>
                                <Box
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: '#7E6E9A',
                                    lineHeight: '15.12px',
                                    fontFamily: 'Outfit',
                                  }}
                                >
                                  Payment <br /> Received
                                </Box>

                                <Box
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    color: '#52526C',
                                    lineHeight: '18px',
                                    fontFamily: 'Outfit',
                                  }}
                                >
                                  ${paymentCard.payment_received_today}{' '}
                                </Box>
                              </Box>
                            </Paper>
                          </Grid>
                          <Grid item xs={12} md={4} sm={4}>
                            <Paper elevation={2} sx={statsStyles}>
                              <Box sx={imageContainerStyles}>
                                <img
                                  src={PaymentDueImage}
                                  alt="earning_dot"
                                  style={{
                                    margin: '0',
                                    width: '50px',
                                    height: '50px',
                                  }}
                                />
                              </Box>
                              <Box>
                                <Box
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: '#7E6E9A',
                                    lineHeight: '15.12px',
                                    fontFamily: 'Outfit',
                                  }}
                                >
                                  Payment <br />
                                  Due
                                </Box>

                                <Box
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    color: '#52526C',
                                    lineHeight: '18px',
                                    fontFamily: 'Outfit',
                                  }}
                                >
                                  ${paymentCard.payment_due_today}{' '}
                                </Box>
                              </Box>
                            </Paper>
                          </Grid>
                          <Grid item xs={12} md={4} sm={4}>
                            <Paper
                              elevation={2}
                              sx={{
                                ...statsStyles,
                                ...AlertstatsStyles,
                                borderRadius: '10px',
                              }}
                            >
                              <Box sx={imageContainerStyles}>
                                <img
                                  src={PaymentFailedImage}
                                  alt="earning_dot"
                                  style={{
                                    margin: '0',
                                    width: '50px',
                                    height: '50px',
                                  }}
                                />
                              </Box>
                              <Box>
                                <Box
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: '#7E6E9A',
                                    lineHeight: '15.12px',
                                    fontFamily: 'Outfit',
                                  }}
                                >
                                  Failed <br />
                                  Payments
                                </Box>

                                <Box
                                  sx={{
                                    fontFamily: 'Outfit',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    color: '#52526C',
                                    lineHeight: '18px',
                                  }}
                                >
                                  ${paymentCard.payment_failed_today}{' '}
                                </Box>
                              </Box>
                            </Paper>
                          </Grid>
                        </Grid>

                        <List
                          sx={{ ...scrollableListStylesButtons, mt: '10px' }}
                        >
                          {paymentNotification.map((item, i) => (
                            <ListItem
                              key={i}
                              sx={{
                                ...listItemNotificationsStyles,
                                borderBottom: '1px solid #fff',
                                paddingY: '0',
                                paddingX: 1,
                                mb: 0,
                              }}
                            >
                              <ListItemText>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    gap: '5px',
                                    justifyContent: 'flex-start',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <img
                                    src={avatar}
                                    style={{
                                      ...avatarStyles,
                                      minWidth: 'unset',
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      fontWeight: '500',
                                      fontSize: '12px',
                                      lineHeight: '15.12px',
                                      fontFamily: 'Outfit',
                                      pl: '5px',
                                    }}
                                  >
                                    Received $<span>{item.payment}</span>
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontWeight: '500',
                                      fontSize: '12px',
                                      color: '#7E6E9A',
                                      lineHeight: '14.06px',
                                    }}
                                  >
                                    from
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontWeight: '500',
                                      fontSize: '12px',
                                      lineHeight: '15.12px',
                                    }}
                                  >
                                    {item.from}
                                  </Typography>
                                </Box>
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <Paper
                        sx={{
                          ...notificationsStyles,
                          height: '318px',
                          display: 'flex',
                          flexDirection: 'column',
                          border: '1px solid rgb(243, 236, 243)',
                          boxShadow: '0px 4px 4px 0px #00000026',
                          gap: '15px',
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: '20px',
                            fontWeight: '500',
                            fontFamily: 'Outfit',
                            lineHeight: '25.2px',
                            letterSpacing: '0.5px',
                            color: 'rgba(63, 47, 91, 1)',
                          }}
                          align="left"
                        >
                          SMS Notifications
                        </Typography>
                        <List sx={scrollableListStyles}>
                          {smsNotifications.map((item, i) => (
                            <ListItem
                              key={i}
                              sx={{
                                ...listItemNotificationsStyles,
                                borderBottom: '1px solid #fff',
                                paddingY: '0',
                                paddingX: 1,
                                mb: 0,
                              }}
                            >
                              <ListItemText>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    gap: '5px',
                                    justifyContent: 'flex-start',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <img
                                    src={avatar}
                                    style={{
                                      ...avatarStyles,
                                      minWidth: 'unset',
                                    }}
                                  />
                                  <Typography
                                    variant="span"
                                    sx={{
                                      color: '#7E6E9A',
                                      fontSize: '12px',
                                      fontWeight: '500',
                                      lineHeight: '15.12px',
                                      textAlign: 'left',
                                      pl: '5px',
                                    }}
                                  >
                                    Message Received from
                                  </Typography>
                                  <Typography
                                    variant="span"
                                    sx={{
                                      color: '#3F2F5B',
                                      fontSize: '12px',
                                      fontWeight: '500',
                                      lineHeight: '15.12px',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {item.from}
                                  </Typography>
                                </Box>
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <Grid container spacing={'24px'}>
                    <Grid item xs={12} sm={6} md={6} lg={12} xl={6}>
                      <RoundedItemSmall
                        imageWidth={'min(70px, 40%)'}
                        imageSrc={DeliveryImage}
                        imageAlt={'delivery_image'}
                        amount5={cardAPiData.active_route_count}
                        amount1={cardAPiData.todays_pending_deliveries}
                        amount2={cardAPiData.todays_completed_deliveries}
                        text={'Delivery Status'}
                        subtext5={'Routes'}
                        subtext1={'Pending'}
                        subtext2={'Completed'}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={12} xl={6}>
                      <RoundedItemSmall
                        imageWidth={'min(70px, 40%)'}
                        imageSrc={MealImage}
                        imageAlt={'meal_image'}
                        amount1={cardAPiData.active_meals}
                        amount2={cardAPiData.active_menu_plans}
                        amount4={cardAPiData.active_food_items}
                        text={'Meal Types'}
                        subtext1={'Meals'}
                        subtext2={'Meal Plans'}
                        subtext4={'Meal Items'}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Paper
                        sx={{
                          ...notificationsStyles,
                          display: 'flex',
                          flexDirection: 'column',
                          height: '521px',
                          gap: '12px',
                        }}
                        elevation={3}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: '20px',
                            fontWeight: '500',
                            fontFamily: 'Outfit',
                            lineHeight: '25.2px',
                            letterSpacing: '0.5px',
                            color: 'rgba(63, 47, 91, 1)',
                          }}
                          align="left"
                        >
                          Delivery Status
                        </Typography>
                        <List
                          sx={{
                            ...BigscrollableListStyles,
                            px: '5px',
                            maxHeight: '100%',
                          }}
                        >
                          {deliveryNotification.map(
                            (item, i) => (
                              console.log('item>>>>', item),
                              (
                                <ListItem
                                  key={i}
                                  sx={{
                                    borderRadius: '10px',
                                    border: '1px solid var(--Line, #F5F6F8)',
                                    background: 'rgba(255, 255, 255, 0.16)',
                                    boxShadow:
                                      '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
                                    backdropFilter: 'blur(20px)',
                                    marginBottom: '10px',
                                  }}
                                >
                                  <ListItemText>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        gap: '10px',
                                        justifyContent: 'space-between',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <img
                                          src={avatar}
                                          style={{
                                            border: '1px solid #7366FF',
                                            width: '37px',
                                            height: '37px',
                                            borderRadius: '100px',
                                            padding: '2px',
                                          }}
                                        />
                                        <Box
                                          sx={{ ml: 2 }}
                                          dangerouslySetInnerHTML={{
                                            __html: item.message,
                                          }}
                                        ></Box>
                                      </Box>

                                      <Box
                                        key={item._id}
                                        sx={{
                                          cursor: item.proof_img
                                            ? 'pointer'
                                            : 'default',
                                        }}
                                        onClick={() =>
                                          item.proof_img &&
                                          handleImageClick(item.proof_img)
                                        }
                                      >
                                        {item.proof_img ? (
                                          <CameraAlt sx={{ mt: 1 }} />
                                        ) : (
                                          <NoPhotography sx={{ mt: 1 }} />
                                        )}
                                      </Box>
                                    </Box>
                                  </ListItemText>
                                  {/* <Box>
                                    <img
                                      src={item.proof_img}
                                      style={{
                                        height: "100px",
                                        width: "100px",
                                      }}
                                    />
                                  </Box> */}
                                </ListItem>
                              )
                            )
                          )}
                        </List>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

const commaSeparateNumber = (number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export default TenantDasboard;
