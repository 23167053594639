import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Card,
  CardContent,
  Divider,
  OutlinedInput,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import availableAmount from '../../../icons/availableAmount.svg';
import couponCode from '../../../icons/couponCode.svg';
import dollor from '../../../icons/dollor.svg';
import currentPlan from '../../../icons/currentPlan.svg';
import oldPlan from '../../../icons/oldPlan.svg';
import AdditionalMenuModal from './AdditionalMenuModal';
import axios from 'axios';
import { TENANT_URL } from '../../../config';
import { parse } from 'date-fns';
import UpdateOverviewModal from './UpdateOverviewModal';

const Subscription = () => {
  const [open, setOpen] = useState(false);

  const [data, setData] = useState({});
  const [menuPlan, setMenuPlan] = useState([]);
  const [menuPlanCategory, setMenuPlanCategory] = useState([]);
  const [planId, setPlanId] = useState(0);

  const [oldPlanID, setOldPlanID] = useState(0);
  const [oldMealID, setOldMealID] = useState(0);

  const [menuPlanId, setMenuPlanId] = useState('0');
  const [newPlan, setNewPlan] = useState('');
  const [newPlanCategory, setNewPlanCategody] = useState('');
  const [newPayment, setNewPayment] = useState('0');

  const [coupanCode, setCoupanCode] = useState('');

  const [totalNewPlanPrice, setTotalNewPlanPrice] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [texableAmount, setTexableAmount] = useState(0);

  const [totalCalculatedAmount, setcalculatedAmount] = useState(0);

  const [taxes, setTaxes] = useState([]);

  const [isUpdatedStatus, setIsUpdatedStatus] = useState(false);

  const [fetchNewPlanCategory, setNewPlanCategory] = useState([]);

  const [newPlanData, setNewPlanData] = useState({});

  const [newAdditionalData, setNewAdditionalData] = useState({});

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonthName, setCurrentMonthName] = useState(
    new Date().toLocaleString('en-US', { month: 'long' }).toLowerCase()
  );

  const [updatePlan, setUpdatePlan] = useState({
    payment: 0,
    menu_plan_id: '',
    subscription_plan: '',
    delivery_time_slot: '',
  });

  const handleClose = (closeData) => {
    if (closeData.data) {
      setNewAdditionalData(closeData.data.data);
      console.log('coloseData:', closeData);
      // fetchDataSubscription('/customer/profile')
      // fetchDataSubscriptionPlan('/api/menu-plans')
    }
    setOpen(false);
  };

  const handleUpgradeSubscription = async () => {
    const url = '/customer/profile';
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      const response = await axios.put(endpoint, updatePlan, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      // console.log("successfully updateddd",response)
      handleClose();
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }
  };

  const fetchDataSubscription = async (url) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setData(response.data.data);
      setPlanId(response.data.data.plan_category_id.toString());
      setMenuPlanId(response.data.data.menu_plan_id.toString());

      setOldPlanID(response.data.data.plan_category_id.toString());
      setOldMealID(response.data.data.menu_plan_id.toString());
    } catch (error) {}
  };

  const fetchCategoryData = async (url) => {
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      const response = await axios.get(
        `${TENANT_URL}/customer/app/plan/category`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setNewPlanCategory(response.data.data);
    } catch (error) {}
  };

  const fetchDataSubscriptionPlan = async (url) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    console.log('$$$$authToken', authToken);
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setMenuPlan(response.data.data);
    } catch (error) {}
  };

  const fetchCategoryPlans = async () => {
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      const response = await axios.get(
        `${TENANT_URL}/menu-plans/plan-category/${planId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setMenuPlanCategory(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getPlanTaxes();
    fetchDataSubscription('/customer/profile');
    fetchDataSubscriptionPlan('/api/menu-plans');
  }, []);

  useEffect(() => {
    fetchCategoryData();
    fetchCategoryPlans();
  }, [planId]);

  useEffect(() => {
    let total =
      (newPlanData?.plan_price || 0).toFixed(2) +
      (newAdditionalData?.total_price || 0).toFixed(2);
    setTotalNewPlanPrice(total);
    recalculateValues();
  }, [newPlanData?.plan_price, newAdditionalData?.total_price]);

  const getNewPlanDetails = async (planID) => {
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var mealConfig = {
        method: 'get',
        url: `${TENANT_URL}/customer/app/menu-plan/${planID}?month=${currentMonthName}&year=${currentYear}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios({ ...mealConfig });
      setNewPlanData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const BootstrapInput = {
    'label + &': {
      marginTop: '10px',
    },
    '& .MuiInputBase-input': {
      position: 'relative',
      backgroundColor: '#fff',
      fontSize: 16,
      padding: '15px',
      height: '62px',
      boxSizing: 'border-box',
      borderRadius: '10px',
      border: '1px solid #ffffff',
      background: '#FFF',
      width: '100%',
      '&:focus': {},
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ffffff',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent', // Removes hover border color
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent', // Removes focus border color
    },
    '& .MuiOutlinedInput-root:hover fieldset': {
      border: 'transparent !important', // Removes hover border color
    },
  };

  useEffect(() => {
    recalculateValues();
  }, [discountAmount]);

  const applyCoupanCode = async () => {
    let total =
      (newPlanData?.plan_price || 0) + (newAdditionalData?.total_price || 0);
    if (total > 0) {
      try {
        const token = JSON.parse(localStorage.getItem('tenantdata')).token;
        var mealConfig = {
          method: 'post',
          url: `${TENANT_URL}/customer/app/apply-coupon`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: {
            voucher_code: coupanCode,
            order_total: total,
          },
        };
        const response = await axios({ ...mealConfig });
        if (!!response.data.discount) {
          setDiscountAmount(response.data.discount);
        } else {
          setDiscountAmount(0);
        }
      } catch (error) {
        setDiscountAmount(0);
      }
    } else {
      setDiscountAmount(0);
    }
    recalculateValues();
  };

  const getPlanTaxes = async () => {
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var mealConfig = {
        method: 'get',
        url: `${TENANT_URL}/customer/app/plan/taxes`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios({ ...mealConfig });
      setTaxes(response.data.taxes);
    } catch (error) {}
  };

  const recalculateValues = () => {
    let total =
      (newPlanData?.plan_price || 0) + (newAdditionalData?.total_price || 0);

    let totalAmountCal = parseFloat(total).toFixed(2);
    let discountAmountCal = parseFloat(discountAmount).toFixed(2);
    let accountBalanceCal = parseFloat(data?.account_balance).toFixed(2);

    let finalAmount = totalAmountCal - discountAmountCal;
    finalAmount = finalAmount.toFixed(2);

    let taxAmount = 0;
    taxes.map((elements) => {
      if (elements.type == 'percentage') {
        taxAmount =
          taxAmount +
          (parseFloat(finalAmount) * parseFloat(elements.value)) / 100;
      }
      if (elements.type == 'fixed') {
        taxAmount = taxAmount + elements.value;
      }
      console.warn(elements.value, '---taxAmount---:', taxAmount);
    });

    let totalAmount =
      parseFloat(totalAmountCal) -
      parseFloat(discountAmountCal) +
      parseFloat(taxAmount) -
      parseFloat(accountBalanceCal);
    setcalculatedAmount(totalAmount.toFixed(2));
    return totalAmount.toFixed(2);
  };

  const [open1, setOpen1] = useState(false);
  const handleClose1 = (closeData) => {
    setOpen1(false);
  };

  return (
    <Box
      sx={{
        padding: '20px 2.5em',
        maxWidth: '100%',
        margin: '0 auto',
      }}
    >
      <Grid container spacing={2}>
        {/* Upgrade Subscription Heading */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6" // Makes it consistent as a heading
            sx={{
              fontWeight: 600, // Bold text
              fontSize: '16px',
              marginBottom: '16px', // Add spacing below the heading
              color: '#333', // Consistent color,
              textTransform: 'uppercase',
            }}
          >
            Upgrade Subscription
          </Typography>

          {/* Upgrade Subscription Form */}
          <Box sx={{ marginBottom: '20px' }}>
            <Typography variant="subtitle1" gutterBottom>
              Meal Category*
            </Typography>
            <Select
              fullWidth
              value={planId.toString() || ''} // Ensure default value is a valid string
              className="cust-select"
              IconComponent={ExpandMoreIcon}
              onChange={(e) => {
                const selectedId = e.target.value;
                setPlanId(selectedId);
                const selectedPlan = menuPlan.find(
                  (plan) => plan.plan_category_id === selectedId
                );
                setNewPlan(selectedPlan?.plan_category);
                setNewPlanData({});
                setNewAdditionalData({});
              }}
              sx={{
                padding: '4px',
                fontSize: '14px',
                height: '36px',
                border: '1px solid #EBEBEC', // Add custom border
                borderRadius: '10px', // Optional: adjust border-radius if needed
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none', // Remove the default outline
                },
                '.MuiSelect-icon': {
                  color: '#7E7E7E', // Change the icon color
                },
              }}
            >
              {fetchNewPlanCategory.map((element, index) => (
                <MenuItem key={index} value={(element?.id).toString()}>
                  {element?.name || 'Select a category'}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <Typography variant="subtitle1" gutterBottom>
              Meal*
              <Button
                sx={{
                  textDecoration: 'underline',
                  color: '#F0645B',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: 'transparent', // Removes the background color on hover
                  },
                }}
                className="additionalButton"
                onClick={() => setOpen(true)}
              >
                Additional items
              </Button>
            </Typography>

            <Select
              fullWidth
              value={menuPlanId.toString() || ''}
              className="cust-select"
              onChange={(e) => {
                const selectedId = e.target.value;
                const selectedPlanData = menuPlanCategory.find(
                  (plan) => plan.id.toString() === selectedId.toString()
                );

                setMenuPlanId(selectedPlanData.id.toString());
                setNewPlanCategody(selectedPlanData.name);
                setNewPayment(selectedPlanData.plan_price);

                if (selectedPlanData.id.toString() == oldMealID) {
                  setNewPlanData({});
                  setNewAdditionalData({});
                  setIsUpdatedStatus(false);
                } else {
                  getNewPlanDetails(e.target.value);
                  setNewPlanData({});
                  setNewAdditionalData({});
                  setIsUpdatedStatus(true);
                }
              }}
              IconComponent={ExpandMoreIcon}
              sx={{
                padding: '4px',
                fontSize: '14px',
                height: '36px',
                border: '1px solid #EBEBEC',
                borderRadius: '10px',
                '.MuiOutlinedInput-notchedOutline': { border: 'none' },
                '.MuiSelect-icon': { color: '#7E7E7E' },
              }}
            >
              <MenuItem value="0" disabled>
                Select a menu plan
              </MenuItem>
              {menuPlanCategory.map((element, index) => (
                <MenuItem value={element.id.toString()}>
                  {element?.name || 'Select a menu plan'}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <Typography variant="subtitle1" gutterBottom>
              Subscription Plan*
            </Typography>

            <Select
              fullWidth
              value={data?.subscription_details?.plan_name || ''} // Ensure default value is a valid string
              className="cust-select"
              onChange={(e) => {
                setUpdatePlan({
                  ...updatePlan,
                  subscription_plan: e.target.value,
                });
                setIsUpdatedStatus(true);
              }}
              IconComponent={ExpandMoreIcon}
              sx={{
                padding: '4px',
                fontSize: '14px',
                height: '36px',
                border: '1px solid #EBEBEC', // Add custom border
                borderRadius: '10px', // Optional: adjust border-radius if needed
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none', // Remove the default outline
                },
                '.MuiSelect-icon': {
                  color: '#7E7E7E', // Change the icon color
                },
              }}
            >
              {/* <MenuItem value={data?.subscription_details?.plan_name}>
                {data?.subscription_details?.plan_name|| 'Select a subscription'}
              </MenuItem> */}
              <MenuItem value={'monthly'}>Monthly</MenuItem>
            </Select>
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <Typography variant="subtitle1" gutterBottom>
              Available Slot*
            </Typography>
            {/* <Select
              fullWidth
              defaultValue=""
              value={updatePlan?.delivery_time_slot}
              onChange={(e) => setUpdatePlan({ ...updatePlan, delivery_time_slot: e.target.value })}
              className="cust-select"
              IconComponent={ExpandMoreIcon}
              sx={{
                padding: '4px',
                fontSize: '14px',
                height: '36px',
                border: '1px solid #EBEBEC', // Add custom border
                borderRadius: '10px', // Optional: adjust border-radius if needed
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none', // Remove the default outline
                },
                '.MuiSelect-icon': {
                  color: '#7E7E7E', // Change the icon color
                },
              }}
            >
              <MenuItem value={data?.delivery_slot}>{data?.delivery_slot}</MenuItem>
            </Select> */}

            {/* <Select
              fullWidth
              value={data?.delivery_slot || ''} // Ensure default value is a valid string
              className="cust-select"
              onChange={(e) =>
                setUpdatePlan({
                  ...updatePlan,
                  delivery_time_slot: e.target.value,
                })
              }
              IconComponent={ExpandMoreIcon}
              sx={{
                padding: '4px',
                fontSize: '14px',
                height: '36px',
                border: '1px solid #EBEBEC', // Add custom border
                borderRadius: '10px', // Optional: adjust border-radius if needed
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none', // Remove the default outline
                },
                '.MuiSelect-icon': {
                  color: '#7E7E7E', // Change the icon color
                },
              }}
            >
             
              <MenuItem value={'Breakfast'}>Breakfast</MenuItem>
              <MenuItem value={'Lunch'}>Lunch</MenuItem>
              <MenuItem value={'Dinner'}>Dinner</MenuItem>
            </Select> */}
            <Select
              fullWidth
              value={updatePlan.delivery_time_slot} // Use state value
              className="cust-select"
              onChange={(e) =>
                setUpdatePlan((prev) => ({
                  ...prev,
                  delivery_time_slot: e.target.value, // Update delivery slot correctly
                }))
              }
              IconComponent={ExpandMoreIcon}
              sx={{
                padding: '4px',
                fontSize: '14px',
                height: '36px',
                border: '1px solid #EBEBEC', // Custom border
                borderRadius: '10px',
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none', // Remove default outline
                },
                '.MuiSelect-icon': {
                  color: '#7E7E7E', // Change the icon color
                },
              }}
            >
                <MenuItem value="0" disabled>
                Select a available slot
              </MenuItem>
              <MenuItem value={'Breakfast'}>Breakfast</MenuItem>
              <MenuItem value={'Lunch'}>Lunch</MenuItem>
              <MenuItem value={'Dinner'}>Dinner</MenuItem>
            </Select>
          </Box>
          {/* <Box sx={{ marginBottom: '20px' }}>
            <Typography variant="subtitle1" gutterBottom>
              Add-Ons*
            </Typography>
            <Select
              fullWidth
              defaultValue=""
              className="cust-select"
              IconComponent={ExpandMoreIcon}
              sx={{
                padding: '4px',
                fontSize: '14px',
                height: '36px',
              }}
            >
              <MenuItem value="category1">Category 1</MenuItem>
              <MenuItem value="category2">Category 2</MenuItem>
            </Select>
          </Box> */}
        </Grid>

        {/* Available Plans Heading */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600, // Match Upgrade Subscription style
              fontSize: '16px',
              marginBottom: '22px',
              color: '#333',
              textTransform: 'uppercase',
            }}
          >
            Available Plans
          </Typography>

          {/* Available Plans Content */}
          {/* <Box
      sx={{
        backgroundColor: "#f9f9f9", // Light background
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "16px",
      }}
    > */}
          <Card
            sx={{
              marginBottom: '20px',
              boxShadow: '0px 6px 5px 0px #080F340F',
              border: '1px solid #EBEBEC',
              borderRadius: '10px',
            }}
          >
            <CardContent>
              <Grid container>
                <Grid item container sx={{ paddingBottom: '12px' }}>
                  <img src={currentPlan} height={24} width={24} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  // spacing={2}
                  sx={{ paddingBottom: '6px' }}
                >
                  <Grid item xs={10}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: '14px', fontWeight: 600 }}
                    >
                      Current Plan Details
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        textAlign: 'end',
                      }}
                    >
                      ${(data?.plan_details?.plan_price || 0).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sx={{ paddingTop: '0px', marginBottom: '12px' }}>
                  <Typography variant="body1" sx={{ color: '#4F4D55' }}>
                    {data?.plan_details?.item_names}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                spacing={2}
                sx={{ paddingBottom: '6px' }}
              >
                <Grid item xs={10}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '14px', fontWeight: 600 }}
                  >
                    Addon
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '14px', fontWeight: 700, textAlign: 'end' }}
                  ></Typography>
                </Grid>
              </Grid>
              <Typography
                variant="body1"
                sx={{ marginBottom: '12px', color: '#4F4D55' }}
              >
                {data?.plan_details?.addon_names}
              </Typography>

              <Grid
                item
                xs={12}
                container
                spacing={2}
                sx={{ paddingBottom: '6px' }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  // spacing={2}
                  sx={{ paddingBottom: '6px' }}
                >
                  <Grid item xs={10}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: '14px', fontWeight: 600 }}
                    >
                      Additional Items
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        textAlign: 'end',
                      }}
                    >
                      $
                      {(
                        data?.additional_plan_details?.total_price || 0
                      ).toFixed(2)}
                      {/* ${data?.additional_plan_details?.total_price} */}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Typography variant="body1" sx={{ color: '#4F4D55' }}>
                {data?.additional_plan_details?.items}
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              marginBottom: '20px',
              boxShadow: '0px 6px 5px 0px #080F340F',
              border: '1px solid #EBEBEC',
              borderRadius: '10px',
            }}
          >
            <CardContent>
              <Grid container>
                <Grid item container sx={{ paddingBottom: '12px' }}>
                  <img src={oldPlan} height={24} width={24} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  // spacing={2}
                  sx={{ paddingBottom: '6px' }}
                >
                  <Grid item xs={10}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: '14px', fontWeight: 600 }}
                    >
                      New Plan Details
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        textAlign: 'end',
                      }}
                    >
                      ${newPlanData?.plan_price || '0'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sx={{ paddingTop: '0px', marginBottom: '12px' }}>
                  <Typography variant="body1" sx={{ color: '#4F4D55' }}>
                    {newPlanData?.food_items || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                spacing={2}
                sx={{ paddingBottom: '6px' }}
              >
                <Grid item xs={10}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '14px', fontWeight: 600 }}
                  >
                    Addon
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '14px', fontWeight: 700, textAlign: 'end' }}
                  ></Typography>
                </Grid>
              </Grid>
              <Typography
                variant="body1"
                sx={{ marginBottom: '12px', color: '#4F4D55' }}
              >
                {newPlanData?.addons || '-'}
              </Typography>

              <Grid
                item
                xs={12}
                container
                spacing={2}
                sx={{ paddingBottom: '6px' }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  // spacing={2}
                  sx={{ paddingBottom: '6px' }}
                >
                  <Grid item xs={10}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: '14px', fontWeight: 600 }}
                    >
                      Additional Items
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        textAlign: 'end',
                      }}
                    >
                      ${(newAdditionalData?.total_price || 0).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Typography variant="body1" sx={{ color: '#4F4D55' }}>
                {newAdditionalData?.items || '-'}
              </Typography>
            </CardContent>
          </Card>
          {/* </Box> */}
        </Grid>
      </Grid>

      {/* Payment Detail */}
      <Box sx={{ marginTop: '40px' }}>
        <Typography variant="h6" gutterBottom>
          PAYMENT DETAIL
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: '24px', marginBottom: '30px' }}
        >
          <Grid item xs={6} md={3}>
            <Card sx={{ borderRadius: '10px' }}>
              <Grid container sx={{ padding: '17px 18px' }}>
                <Grid item xs={2} sx={{ paddingTop: 0 }}>
                  <img src={availableAmount} height={27} width={27} />
                </Grid>
                <Grid item xs={10} sx={{ paddingTop: 0 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '15.7px', color: '#3F2F5B' }}
                  >
                    Available Amount: ${data?.account_balance}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={6} md={3}>
            <Card sx={{ borderRadius: '10px' }}>
              <Grid container sx={{ padding: '17px 18px' }}>
                <Grid item xs={2} sx={{ paddingTop: 0 }}>
                  <img src={availableAmount} height={27} width={27} />
                </Grid>
                <Grid item xs={10} sx={{ paddingTop: 0 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '15.7px', color: '#3F2F5B' }}
                  >
                    Due Amount: $
                    {totalCalculatedAmount >= 0 ? totalCalculatedAmount : '0'}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={6} md={3}>
            <Card sx={{ borderRadius: '10px' }}>
              <Grid container>
                <Grid item xs={9}>
                  <TextField
                    placeholder={'Apply Coupon Code'}
                    sx={BootstrapInput}
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    style={{
                      width: '100%',
                    }}
                    value={coupanCode}
                    onChange={(e) => setCoupanCode(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    backgroundColor: '#F0645B',
                    minHeight: '61px',
                    display: 'flex', // Enables flexbox
                    justifyContent: 'center', // Centers horizontally
                    alignItems: 'center',
                    borderRadius: '10px',
                    cursor: 'pointer', //
                  }}
                  onClick={applyCoupanCode}
                >
                  <img src={couponCode} height={27} width={27} />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card sx={{ borderRadius: '10px' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: '139px',
                        paddingLeft: '15px',
                        position: 'relative',
                        zIndex: 2,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontSize: '15.7px', color: '#3F2F5B' }}
                      >
                        Amount Payable:
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: '-10px',
                      }}
                    >
                      <TextField
                        placeholder={'Amount'}
                        sx={{
                          ...BootstrapInput,
                        }}
                        variant="outlined"
                        InputProps={
                          {
                            // disableUnderline: true,
                          }
                        }
                        style={{
                          width: '100%',
                        }}
                        value={
                          totalCalculatedAmount >= 0
                            ? '$' + totalCalculatedAmount
                            : '$0'
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
                {/* <Grid
                  item
                  xs={3}
                  sx={{
                    backgroundColor: '#F0645B',
                    minHeight: '61px',
                    display: 'flex', // Enables flexbox
                    justifyContent: 'center', // Centers horizontally
                    alignItems: 'center',
                    borderRadius: '10px',
                  }}
                >
                  <img src={dollor} height={27} width={27} />
                </Grid> */}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Grid item xs={12} lg={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '3px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              color: '#3F2F5B',
              opacity: 0.6,
            }}
          >
            New Plan
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '600',
              color: '#000000',
            }}
          >
            ${(newPlanData?.plan_price || 0).toFixed(2)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '3px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              color: '#3F2F5B',
              opacity: 0.6,
            }}
          >
            Additional Items
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '600',
              color: '#000000',
            }}
          >
            ${(newAdditionalData?.total_price || 0).toFixed(2)}
          </Typography>
        </Box>
        {discountAmount > 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '3px',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#3F2F5B',
                opacity: 0.6,
              }}
            >
              Coupon
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#000000',
              }}
            >
              ${discountAmount}
            </Typography>
          </Box>
        )}

        {taxes.map((element) => {
          return (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '3px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#3F2F5B',
                    opacity: 0.6,
                  }}
                >
                  {element.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#000000',
                  }}
                >
                  {element.type == 'percentage' ? (
                    element.value + '%'
                  ) : (
                    <>{element.value == '0' ? 'Free' : '$' + element.value}</>
                  )}
                </Typography>
              </Box>
            </>
          );
        })}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '3px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              color: '#3F2F5B',
              opacity: 0.6,
            }}
          >
            Wallet Amount
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '600',
              color: '#000000',
            }}
          >
            - ${data?.account_balance || 0}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '3px',
            borderTop: '1px solid rgba(0, 0, 0,0.2)',
            paddingTop: '10px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              color: '#3F2F5B',
              opacity: 0.6,
            }}
          >
            Total Amount Payment
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '600',
              color: '#000000',
            }}
          >
            {parseFloat(totalNewPlanPrice) <= 0 ? (
              <>$0</>
            ) : (
              <>${totalCalculatedAmount >= 0 ? totalCalculatedAmount : '0'}</>
            )}
          </Typography>
        </Box>
      </Grid>

      {/* Buttons */}
      <Box
        sx={{
          marginTop: '20px',
          display: 'flex',
          gap: '22px',
          justifyContent: 'end',
        }}
      >
        <Button
          variant="contained"
          sx={{ backgroundColor: '#F0645B' }}
          // onClick={handleUpgradeSubscription}
          onClick={() => setOpen1(true)}
          disabled={!newPlanData || Object.keys(newPlanData).length === 0}
        >
          Update
        </Button>
        <Button
          variant="outlined"
          sx={{
            marginRight: '10px',
            border: '1px solid #F0645B',
            color: '#F0645B',
          }}
          onClick={() => setOpen(true)}
        >
          Add Additional Items
        </Button>
      </Box>
      <AdditionalMenuModal
        open={open}
        handleClose={handleClose}
        isUpdated={isUpdatedStatus}
      />
      <UpdateOverviewModal
        open={open1}
        handleClose={handleClose1}
        newPlanData={newPlanData}
        newAdditionalData={newAdditionalData}
        subscription={data}
        discountCoupan={{
          discountAmount: discountAmount ?? 0,
          coupanCode: coupanCode ?? '',
        }}
      />
    </Box>
  );
};

export default Subscription;
