import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CustomizationLandingPageLayout from '../serviceLandingPage/pages/CustomizationLandingPage/components/CustomizationLandingPageLayout';
import './style.css';
import { ReactComponent as HouseIcon } from './asstes/House.svg';
import { ReactComponent as ArrowRightIcon } from './asstes/arrowright.svg';
import { ReactComponent as CreditIcon } from './asstes/credit.svg';
import Checkbox from '@mui/material/Checkbox';
import Tiffin_Img from './asstes/tiffin.jpg';
import axios from 'axios';
import { TENANT_URL } from '../../../config';
import AfterpayLogo from './asstes/brand-afterpay-svgrepo-com.svg';
import MastercardLogo from './asstes/mastercard.svg';
import GooglePayLogo from './asstes/google-pay.svg';


import { ServiceFooter } from '../serviceLandingPage/servicefooter';
import { ServiceNavbar } from '../serviceLandingPage/serviceNarbar';

const inpuStyle = {
  width: '100%',

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#EBEBEC',
  },
  '& .MuiOutlinedInput-root': {
    borderColor: '#EBEBEC',
    fontFamily: 'Inter !important',
    fontSize: '14px',
  },
  '& .MuiOutlinedInput-input': {
    height: '1em',
  },
};

const selectStyle = {
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#EBEBEC',
  },
  '& .MuiSelect-select': {
    padding: '13px 14px',
    fontSize: '14px',
  },
};

const Cart = () => {
  const [cartID, setCartID] = useState('');
  const [cartDetails, setCartDetails] = useState({});
  const [cartTaxesDetails, setCartTaxesDetails] = useState([]);

  const [additinaolItems, setAdditinaolItems] = useState('');
  const [additinaolItemsTotals, setAdditinaolItemsTotals] = useState(0);

  const [deliveryMethod, setDeliveryMethod] = useState('');

  const navigate = useNavigate();

  const getCartID = () => {
    let cartID = localStorage.getItem('cartID');
    if (!!cartID) {
      setCartID(cartID);
      setDeliveryMethod(localStorage.getItem('deliveryMethod'));
    } else {
      navigate('/');
    }
  };
  const getCartDetails = async () => {
    console.log('calling');
    try {
      // Fetch meal plan details
      const config = {
        method: 'get',
        url: `${TENANT_URL}/api/cart?cart_id=${cartID}`,
      };
      const response = await axios(config);
      console.log(response.data.cart.items.length);
      if (response.data && response.data.cart.items.length > 0) {
        setCartDetails(response.data);
        getCartTaxes();

        setAdditinaolItems(localStorage.getItem('additinoalitems') || '');
        setAdditinaolItemsTotals(
          localStorage.getItem('additinoalitemTotal') || 0
        );
      } else {
        navigate('/');
      }
    } catch (error) {
      setError('Failed to load meal plan details.');
    }
  };

  const placeOrder = () => {
    navigate('/checkout');
  };

  const getCartTaxes = async () => {
    try {
      const config = {
        method: 'get',
        url: `${TENANT_URL}/api/cart/taxes?cart_id=${cartID}&preferred_delivery_method=${deliveryMethod}`,
      };
      const response = await axios(config);
      setCartTaxesDetails(response.data.taxes);
    } catch (error) {
      setError('Failed to load meal plan details.');
    }
  };

  useEffect(() => {
    document.body.classList.add('customPage');
    return () => {
      document.body.classList.remove('customPage');
    };
  }, []);
  useEffect(() => {
    getCartID();
  }, []);

  useEffect(() => {
    getCartDetails();
  }, [cartID, deliveryMethod]);

  const paymentMethods = [
    {
      name: 'Visa',
      icon: (
        <img src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png" alt="Visa" width="40"/>
      ),
    },
    {
      name: 'Mastercard',
      icon: (
        <img src={MastercardLogo} alt="Mastercard"  width="30"  />
      ),
    },
    {
      name: 'Apple Pay',
      icon: (
        <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg" alt="Apple Pay" width="30" height={30} />

      ),
    },
    {
      name: 'Google Pay',
      icon: (
        <img src={GooglePayLogo} alt="Google Pay" width="30"/>
      ),
    },
    {
      name: 'AfterPay',
      icon: (
        <img src={AfterpayLogo} alt="Afterpay" width="30"/>
      ),
    },
  ];

  const [checked, setChecked] = useState(true);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <ServiceNavbar />
      <CustomizationLandingPageLayout>
        <div className="checkout-page">
          {/* BreadCrumbs */}
          <Grid
            container
            sx={{
              padding: '1em 5em',
              backgroundColor: '#F0645B',
              '@media (max-width:1199px)': {
                padding: '1em 1em',
              },
            }}
          >
            <Grid item md={12}>
              <Box
                display={'flex'}
                flex={1}
                flexDirection={'row'}
                alignItems={'center'}
                gap={1}
              >
                <HouseIcon onClick={() => navigate('/')} />
                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={15}
                  color={'#ffffff'}
                  fontWeight={'bold'}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate('/')}
                >
                  Home
                </Typography>
                <ArrowRightIcon />
                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={15}
                  color={'#ffffff'}
                  fontWeight={'bold'}
                >
                  Checkout
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              padding: '2em 5em',
              '@media (max-width:1199px)': {
                padding: '2em 1em',
              },
            }}
            marginBottom={7}
          >
            <Grid item md={12}>
              <Box marginBottom={3}>
                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={18}
                  color={'#000000'}
                >
                  Billing Information
                </Typography>
              </Box>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={8}>
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={'-6px'}
                    >
                      User name
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="First name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Last name"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={'-6px'}
                    >
                      Address
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Enter address"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Unit Number"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item md={3} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Address
                    </Typography>
                    <Select sx={selectStyle} displayEmpty>
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      <MenuItem value={10}>abc</MenuItem>
                      <MenuItem value={20}>abc 1</MenuItem>
                      <MenuItem value={30}>abc 1</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Region/State
                    </Typography>
                    <Select sx={selectStyle} displayEmpty>
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      <MenuItem value={10}>abc</MenuItem>
                      <MenuItem value={20}>abc 1</MenuItem>
                      <MenuItem value={30}>abc 1</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      City
                    </Typography>
                    <Select sx={selectStyle} displayEmpty>
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      <MenuItem value={10}>abc</MenuItem>
                      <MenuItem value={20}>abc 1</MenuItem>
                      <MenuItem value={30}>abc 1</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Zip Code
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Zip Code"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item md={6} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Email
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="First name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Last name"
                    />
                  </Grid>
                </Grid>

                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  onChange={handleCheckboxChange}
                  label="Deliver into same address"
                  sx={{
                    fontFamily: 'Inter !important',
                    marginBottom: '15px',
                  }}
                />

                {/* Delivery Information */}
                {!checked && (
                  <Box>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={18}
                      color={'#000000'}
                      marginBottom={2}
                    >
                      Delivery Information
                    </Typography>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={'-6px'}
                        >
                          User name
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="First name"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Last name"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={'-6px'}
                        >
                          Address
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Enter address"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Unit Number"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item md={3} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Address
                        </Typography>
                        <Select sx={selectStyle} displayEmpty>
                          <MenuItem value="" disabled>
                            Select...
                          </MenuItem>
                          <MenuItem value={10}>abc</MenuItem>
                          <MenuItem value={20}>abc 1</MenuItem>
                          <MenuItem value={30}>abc 1</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Region/State
                        </Typography>
                        <Select sx={selectStyle} displayEmpty>
                          <MenuItem value="" disabled>
                            Select...
                          </MenuItem>
                          <MenuItem value={10}>abc</MenuItem>
                          <MenuItem value={20}>abc 1</MenuItem>
                          <MenuItem value={30}>abc 1</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          City
                        </Typography>
                        <Select sx={selectStyle} displayEmpty>
                          <MenuItem value="" disabled>
                            Select...
                          </MenuItem>
                          <MenuItem value={10}>abc</MenuItem>
                          <MenuItem value={20}>abc 1</MenuItem>
                          <MenuItem value={30}>abc 1</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Zip Code
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Zip Code"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Email
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="First name"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Phone Number
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Last name"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                <Box className="cart_left" marginBottom={2} padding={2}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={18}
                    color={'#000000'}
                    marginBottom={2}
                  >
                    Payment Option
                  </Typography>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="credit"
                    name="radio-buttons-group"
                    style={{
                      marginBottom: '15px',
                    }}
                  >
                    <FormControlLabel
                      value="credit"
                      control={<Radio />}
                      label={
                        <div>
                          <CreditIcon /> Debit/Credit Card
                        </div>
                      }
                    />
                  </RadioGroup>
                  <Box marginBottom={2}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Name on Card
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                    />
                  </Box>
                  <Box marginBottom={2}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Card Number
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                    />
                  </Box>
                  <Grid container spacing={2} marginBottom={2}>
                    <Grid item md={6}>
                      <Typography
                        variant="h4"
                        fontFamily={'Inter !important'}
                        fontSize={14}
                        color={'#000000'}
                        marginBottom={1}
                      >
                        Expire Date
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        sx={inpuStyle}
                        placeholder="DD/YY"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="h4"
                        fontFamily={'Inter !important'}
                        fontSize={14}
                        color={'#000000'}
                        marginBottom={1}
                      >
                        CVC
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        sx={inpuStyle}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={18}
                  color={'#000000'}
                  marginBottom={2}
                >
                  Additional Information
                </Typography>

                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={14}
                  color={'#000000'}
                  marginBottom={1}
                >
                  delivery driver instructions{' '}
                  <span style={{ color: '#77878F' }}>(Optional)</span>
                </Typography>

                <TextareaAutosize
                  placeholder="Notes about your order, e.g. special notes for delivery"
                  style={{
                    width: '100%', // Custom width
                    padding: '10px', // Padding for better appearance
                    fontSize: '14px', // Custom font size
                    borderRadius: '8px', // Rounded corners
                    border: '1px solid #EBEBEC', // Light gray border
                  }}
                  minRows={5} // Minimum rows for the textarea
                  maxRows={6} // Maximum rows for the textarea
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Box className="cart_left" marginBottom={2} padding={2}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={17}
                    color={'#000000'}
                    marginBottom={3}
                  >
                    Order Summary
                  </Typography>

                  {cartDetails.cart ? (
                    <>
                      {cartDetails.cart.items.map((element, index) => (
                        <Box
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          gap="6px"
                          marginBottom={3}
                        >
                          <img
                            src={element.menu_plan.image_url}
                            alt={element.menu_plan.name}
                            className="tImg"
                          />
                          <Box>
                            <Typography
                              variant="h1"
                              fontSize={15}
                              color={'#000000'}
                              fontWeight={'bold'}
                              marginBottom={'2px'}
                            >
                              {element.menu_plan.name}
                            </Typography>
                            <Typography
                              variant="h1"
                              fontSize={12}
                              color={'#000000'}
                              fontWeight={'400'}
                              lineHeight={'18px'}
                            >
                              {element.menu_plan.slug}
                            </Typography>

                            <Typography
                              variant="h1"
                              fontSize={12}
                              color={'#000000'}
                              fontWeight={'400'}
                              lineHeight={'18px'}
                            >
                              Quantity : {element.quantity}
                            </Typography>
                          </Box>
                        </Box>
                      ))}

                      <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        marginBottom={2}
                      >
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#5F6C72'}
                        >
                          Sub-total
                        </Typography>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                        >
                          ${cartDetails.total_price}
                        </Typography>
                      </Box>

                      {cartTaxesDetails.map((element, index) => (
                        <Box
                          key={index}
                          flex={1}
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          marginBottom={3}
                        >
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#5F6C72'}
                          >
                            {element.name}
                          </Typography>
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#000000'}
                          >
                            {element.type == 'fixed' ? (
                              <>
                                {element.value == '0' ? (
                                  'Free'
                                ) : (
                                  <>${element.value} </>
                                )}
                              </>
                            ) : (
                              <>{element.value}%</>
                            )}
                            {/* {element.type == 'percentage' ? '' : '$'}
                            {element.value}
                            {element.type == 'percentage' ? '%' : ''} */}
                          </Typography>
                        </Box>
                      ))}

                      {cartDetails.total_discount > 0 && (
                        <Box
                          flex={1}
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          marginBottom={2}
                        >
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#5F6C72'}
                          >
                            Discount
                          </Typography>
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#000000'}
                          >
                            ${cartDetails.total_discount}
                          </Typography>
                        </Box>
                      )}
                      {!!additinaolItems ? (
                        <>
                          <Box
                            flex={1}
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            marginBottom={3}
                          >
                            <Typography
                              variant="h4"
                              fontFamily={'Inter !important'}
                              fontSize={14}
                              color={'#5F6C72'}
                            >
                              Additional Items
                            </Typography>
                            <Typography
                              variant="h4"
                              fontFamily={'Inter !important'}
                              fontSize={14}
                              color={'#000000'}
                            >
                              ${additinaolItemsTotals}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        marginBottom={2}
                      >
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#5F6C72'}
                        >
                          Tax
                        </Typography>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                        >
                          ${cartDetails.total_tax}
                        </Typography>
                      </Box> */}

                      <Divider
                        sx={{
                          backgroundColor: '#E4E7E9',
                          marginBottom: '15px',
                        }}
                      />

                      <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        marginBottom={2}
                      >
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={16}
                          color={'#000000'}
                        >
                          Total
                        </Typography>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={16}
                          color={'#000000'}
                          fontWeight={'bold'}
                        >
                          {/* ${(parseFloat(cartDetails.final_amount) + parseFloat(additinaolItemsTotals))} */}
                          $
                          {(
                            parseFloat(cartDetails.final_amount) +
                            parseFloat(additinaolItemsTotals)
                          ).toFixed(2)}
                        </Typography>
                      </Box>

                      <Button
                        variant="contained"
                        sx={{
                          width: '100%',
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                        }}
                        onClick={placeOrder}
                      >
                        Place order
                      </Button>
                      <div className="p-4 bg-white rounded-lg shadow-sm">
                        <p className="text-sm text-gray-600 mb-3">
                          Secure payment via:
                        </p>
                        <div className="d-flex items-center gap-2 flex-wrap">
                          {paymentMethods.map((method) => (
                            <div className="rounded border border-gray-200 hover:border-gray-300 p-2">
                              {method.icon}
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CustomizationLandingPageLayout>
      <ServiceFooter />
    </>
  );
};

export default Cart;
