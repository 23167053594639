import React from 'react';
import { BrowserRouter, Route, Navigate } from 'react-router-dom';
import { AdminAuthProvider } from './admin/auth/auth';
import { SnackbarContextProvider } from './components/utils/snackbarProvider/SnackbarContextProvider';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import { TenantAuthProvider } from './tenant/auth/auth';

import { ProfileProvider } from './tenant/customHooks/useProfile';

import Routes from './tenant/routes';

import './styles/fonts/newFonts.css';
function App() {
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  )?.tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData ?? [];
  function getIsActiveByModuleName(data, module_name) {
    for (const permission of data) {
      if (permission.module_name === module_name) {
        return permission.view;
      }
    }
    return false;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarContextProvider>
        <AdminAuthProvider>
          <TenantAuthProvider>
            <ProfileProvider>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </ProfileProvider>
          </TenantAuthProvider>
        </AdminAuthProvider>
      </SnackbarContextProvider>
    </ThemeProvider>
  );
}

export default App;
