import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  FormLabel,
  Checkbox,
  useTheme,
  Button,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import WalletsComponent from './components/WalletsComponent';
import SubscriptionComponent from './components/SubscriptionComponent';
import NewPlanComponent from './components/NewPlanComponent';
import { TENANT_URL } from '../../../config';
import axios from 'axios';

const inputStyle = {
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#EBEBEC',
  },
  '& .MuiOutlinedInput-root': {
    borderColor: '#EBEBEC',
    fontFamily: 'Inter !important',
    fontSize: '14px',
  },
  '& .MuiOutlinedInput-input': {
    height: '1em',
  },
};

const selectStyle = {
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#EBEBEC',
  },
  '& .MuiSelect-select': {
    padding: '13px 14px',
    fontSize: '14px',
  },
};

const OrderCheckout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const checkoutData = location.state;

  if(!checkoutData?.mealFor) {
    navigate("/customer/subscription");
  }

  const [taxes, setTaxes] = useState([]);
  const [totalCalculatedAmount, setcalculatedAmount] = useState(0);

  const [checked, setChecked] = useState(true);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const getPlanTaxes = async () => {
    try {
      console.log("Asdasd2");
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var mealConfig = {
        method: 'get',
        url: `${TENANT_URL}/customer/app/plan/taxes?preferred_delivery_method=${checkoutData?.mealFor}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      console.log("mealConfig:",mealConfig);

      const response = await axios({ ...mealConfig });
      console.log("response:",response);
      setTaxes(response.data.taxes);
      recalculateValues();
    } catch (error) {}
  };

  const recalculateValues = () => {
    let total = (checkoutData?.newPlan?.plan_price || 0) + (checkoutData?.newAdditional?.total_price || 0);
  
    let totalAmountCal = parseFloat(total);
    let discountAmountCal = parseFloat(checkoutData?.discountCoupan?.discountAmount);
    let accountBalanceCal = parseFloat(checkoutData?.subscription?.account_balance);
  
    let finalAmount = totalAmountCal - discountAmountCal;
    finalAmount = finalAmount.toFixed(2);
  
    let taxAmount = 0;
    taxes.map((elements) => {
      if (elements.type == 'percentage') {
        taxAmount =
          taxAmount +
          (parseFloat(finalAmount) * parseFloat(elements.value)) / 100;
      }
      if (elements.type == 'fixed') {
        taxAmount = taxAmount + elements.value;
      }
      console.warn(elements.value, '---taxAmount---:', taxAmount);
    });
  
    let totalAmount =
      parseFloat(totalAmountCal) -
      parseFloat(discountAmountCal) +
      parseFloat(taxAmount) -
      parseFloat(accountBalanceCal);
    setcalculatedAmount(totalAmount.toFixed(2));
    return totalAmount.toFixed(2);
  };
  

  useEffect(() => {  
    recalculateValues();
  }, [taxes])

  useEffect(() => {
    getPlanTaxes();
  }, [checkoutData?.mealFor])

  console.log("taxes:",taxes);

  

  const SPGridHeaderSubmit = ({ children, onClick, sx }) => {
    const theme = useTheme();

    return (
      <Button
        onClick={onClick}
        variant="filled"
        sx={{
          width: 'inherit',
          borderRadius: '25px',
          textTransform: 'capitalize',
          marginLeft: '10px',
          py: 1.4,
          fontWeight: 400,
          color: 'white',

          boxShadow: 'none',
          marginLeft: '10px',
          ...sx,
        }}
        type="submit"
      >
        {children}
      </Button>
    );
  };

  const SPGridHeaderButton = ({ children, onClick, sx }) => {
    const theme = useTheme();
    return (
      <div style={{marginRight: '10px'}}>
        <Button
        onClick={onClick}
        variant="outlined"
        sx={{
          marginRight: '10px',
          border: '1px solid #F0645B',
          color: '#F0645B',
        }}
      >
        {children}
      </Button>
      </div>
      
    );
  };
  return (
    <div className="checkout-page">
      <Grid
        container
        sx={{
          padding: '1em 2em',
          '@media (max-width:1199px)': {
            padding: '1em 1em',
          },
        }}
        spacing={2}
      >
        <Grid item md={6}>
          {/* Payment Section */}
          <Box
            sx={{
              border: '1px solid #ff7676',
              borderRadius: '12px',
              padding: '10px 15px 0',
              marginBottom: '1em',
            }}
          >
            <RadioGroup defaultValue="credit">
              <FormControlLabel
                value="credit"
                control={<Radio />}
                label="Debit/Credit Card"
              />
            </RadioGroup>

            {/* Card Information */}
            <Grid container spacing={2} marginBottom={2}>
              <Grid item md={6} xs={12}>
                <FormLabel
                  sx={{
                    fontSize: '14px',
                    marginBottom: '6px',
                  }}
                >
                  Name on card *
                </FormLabel>
                <TextField
                  variant="outlined"
                  sx={inputStyle}
                  placeholder="Enter name"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormLabel
                  sx={{
                    fontSize: '14px',
                    marginBottom: '6px',
                  }}
                >
                  Card Number *
                </FormLabel>
                <TextField
                  variant="outlined"
                  sx={inputStyle}
                  placeholder="Enter card number"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormLabel
                  sx={{
                    fontSize: '14px',
                    marginBottom: '6px',
                  }}
                >
                  Expiry Date *
                </FormLabel>
                <TextField
                  variant="outlined"
                  sx={inputStyle}
                  placeholder="MMYY"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormLabel
                  sx={{
                    fontSize: '14px',
                    marginBottom: '6px',
                  }}
                >
                  CVC *
                </FormLabel>
                <TextField
                  variant="outlined"
                  sx={inputStyle}
                  placeholder="123"
                />
              </Grid>
            </Grid>
          </Box>

          <FormControlLabel
            control={<Checkbox defaultChecked />}
            onChange={handleCheckboxChange}
            label="Deliver into same address"
            sx={{
              fontFamily: 'Inter !important',
              marginBottom: '15px',
            }}
          />

          {/* Delivery Information */}
          {!checked && (
            <Box>
              <Typography
                variant="h4"
                fontFamily={'Inter !important'}
                fontSize={18}
                color={'#000000'}
                marginBottom={2}
              >
                Delivery Information
              </Typography>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={'7px'}
                  >
                    First Name *
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Enter first name"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={'7px'}
                  >
                    Last Name *
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Enter last name"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={1}
                  >
                    Email
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Enter Email"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={1}
                  >
                    Phone Number
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Enter phone number"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={'7px'}
                  >
                    Address *
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Street address"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={'7px'}
                  >
                    Unit *
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Apt, Suite, Unit"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item md={3} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={1}
                  >
                    Country
                  </Typography>
                  <Select sx={selectStyle} displayEmpty>
                    <MenuItem value="" disabled>
                      Select...
                    </MenuItem>
                    <MenuItem value={10}>abc</MenuItem>
                    <MenuItem value={20}>abc 1</MenuItem>
                    <MenuItem value={30}>abc 1</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={1}
                  >
                    Region/State
                  </Typography>
                  <Select sx={selectStyle} displayEmpty>
                    <MenuItem value="" disabled>
                      Select...
                    </MenuItem>
                    <MenuItem value={10}>abc</MenuItem>
                    <MenuItem value={20}>abc 1</MenuItem>
                    <MenuItem value={30}>abc 1</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={1}
                  >
                    City
                  </Typography>
                  <Select sx={selectStyle} displayEmpty>
                    <MenuItem value="" disabled>
                      Select...
                    </MenuItem>
                    <MenuItem value={10}>abc</MenuItem>
                    <MenuItem value={20}>abc 1</MenuItem>
                    <MenuItem value={30}>abc 1</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={1}
                  >
                    Zip Code
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Zip Code"
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>

        {/* Order Summary */}
        <Grid item md={6}>
          <WalletsComponent walletAmount={checkoutData?.subscription?.account_balance || 0}/>
          <Grid container spacing={2} className="my-1">
            <Grid item md={12} lg={6} xl={6}>
              <SubscriptionComponent 
                planDetail={checkoutData?.subscription?.plan_details?.item_names}
                planFree={checkoutData?.subscription?.plan_details?.plan_price}
                addon={checkoutData?.subscription?.plan_details?.addon_names}
                additionalDetail={checkoutData?.subscription?.additional_plan_details?.items}
                additionalFee={checkoutData?.subscription?.additional_plan_details?.total_price}
              />
            </Grid>
            <Grid item md={12} lg={6} xl={6}>
              <NewPlanComponent 
                planDetail={checkoutData?.newPlan?.food_items}
                planFree={checkoutData?.newPlan?.plan_price}
                addon={checkoutData?.newPlan?.addons}
                additionalDetail={checkoutData?.newAdditional?.items}
                additionalFee={checkoutData?.newAdditional?.total_price}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '3px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#3F2F5B',
                    opacity: 0.6,
                  }}
                >
                  New Plan
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#000000',
                  }}
                >
                  ${(checkoutData?.newPlan?.plan_price || 0).toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '3px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#3F2F5B',
                    opacity: 0.6,
                  }}
                >
                  Additional Items
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#000000',
                  }}
                >
                  ${(checkoutData?.newAdditional?.total_price || 0).toFixed(2)}
                </Typography>
              </Box>
              {(checkoutData?.discountCoupan?.discountAmount > 0 &&
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '3px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#3F2F5B',
                    opacity: 0.6,
                  }}
                >
                  Coupon
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#000000',
                  }}
                >
                  {/* -${checkoutData?.discountCoupan?.discountAmount || 0} */}
                  {
                   checkoutData?.discountCoupan?.discountAmount > 0
                   ? `-${checkoutData.discountCoupan.discountAmount}`
                   : checkoutData?.discountCoupan
                   ? `${checkoutData.discountCoupan.discountAmount}`
                   : "0"
                  }
                </Typography>
              </Box>
              )}

              {taxes.map((tax, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '3px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '500',
                      color: '#3F2F5B',
                      opacity: 0.6,
                    }}
                  >
                    {tax.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#000000',
                    }}
                  >
                    {tax.type == 'percentage' ? (
                      tax.value + '%'
                    ) : (
                      <>{tax.value == '0' ? 'Free' : '$' + tax.value}</>
                    )}
                  </Typography>
                </Box>
              ))}

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '3px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#3F2F5B',
                    opacity: 0.6,
                  }}
                >
                  Wallet Amount
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#000000',
                  }}
                >
                  -${checkoutData?.subscription?.account_balance || 0}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '3px',
                  borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                  paddingTop: '10px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#3F2F5B',
                    opacity: 0.6,
                  }}
                >
                  Total Amount Payment
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#000000',
                  }}
                >
                  ${totalCalculatedAmount<=0 ? 0 : (totalCalculatedAmount || 0) }
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          sx={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'flex-end',
          }}
        >
          <SPGridHeaderButton variant="filled" text={'Back'} onClick={()=> navigate("/customer/subscription")}>
            Back
          </SPGridHeaderButton>
          <SPGridHeaderSubmit variant="filled" text={'Update'}>
            Submit
          </SPGridHeaderSubmit>
          
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderCheckout;
