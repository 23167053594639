import axios from 'axios';

const hostname = window.location.hostname.split('.');
const backendUrl = process.env.REACT_APP_TENANT_BACKEND_URL;
const mainUrl = process.env.REACT_APP_BACKEND_URL;
const backendProtocol = process.env.REACT_APP_TENANT_BACKEND_PROTOCOL;
const domainName = process.env.REACT_APP_DOMAIN_NAME

let TENANT_URL = localStorage.getItem('tenantURL') 
// || `${backendProtocol}://${hostname[0] != 'localhost' ? hostname[0] : domainName}.${backendUrl}`;
let TENANT_ID = localStorage.getItem('tenantid') 
// || hostname[0];

const fetchTenantData = async () => {
  try {
    const { data } = await axios.get(`${mainUrl}/tenant/valid/${hostname[0] != 'localhost' ? hostname[0] : domainName}`);
   
    if (!data?.isValid) {
      console.warn('Invalid tenant:', hostname[0]);
      return { tenantUrl: null, tenantId: null };
    }

    const tenantUrl = `${backendProtocol}://${hostname[0] != 'localhost' ? hostname[0] : domainName}.${backendUrl}`;
    localStorage.setItem('tenantURL', tenantUrl);

    const response = await axios.get(`${tenantUrl}/tenant/id`);
    const tenantId = response.data.toLowerCase();
    localStorage.setItem('tenantid', tenantId);

    return { tenantUrl, tenantId };
  } catch (error) {
    console.error('Error fetching tenant data:', error);
    return { tenantUrl: null, tenantId: null };
  }
};

const initializeTenant = async () => {
  if (!TENANT_URL || !TENANT_ID) {
    const { tenantUrl, tenantId } = await fetchTenantData();
    TENANT_URL = tenantUrl;
    TENANT_ID = tenantId;
  }
};

initializeTenant().then((res) => res).catch((err) => err);

export { TENANT_URL, TENANT_ID };
